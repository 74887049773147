var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","cancel-variant":"outline-secondary","ok-title":_vm.$t('Сохранить'),"no-close-on-backdrop":"","cancel-title":_vm.$t('Отмена'),"centered":"","title":_vm.title},on:{"ok":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите паспорт'),"label-for":"v-passport"}},[_c('validation-provider',{attrs:{"name":"v-passport","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('AA#######'),expression:"'AA#######'"}],attrs:{"id":"v-passport","state":errors.length > 3 ? false : null,"placeholder":"AA0000000"},model:{value:(_vm.form.passport),callback:function ($$v) {_vm.$set(_vm.form, "passport", $$v)},expression:"form.passport"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите ПИНФЛ'),"label-for":"v-pin"}},[_c('validation-provider',{attrs:{"name":"v-pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##############'),expression:"'##############'"}],attrs:{"id":"v-pin","state":errors.length > 3 ? false : null,"placeholder":"00000000000000"},model:{value:(_vm.form.pin),callback:function ($$v) {_vm.$set(_vm.form, "pin", $$v)},expression:"form.pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите телефон'),"label-for":"v-phone"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Введите телефон'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +998 ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"id":"v-phone","name":"v-phone","state":errors.length > 3 ? false : null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Фамилия'),"label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 3 ? false : null,"name":"lastname","placeholder":""},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Имя'),"label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","state":errors.length > 3 ? false : null,"name":"firstname","placeholder":""},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Отчества'),"label-for":"middlename"}},[_c('validation-provider',{attrs:{"name":"middlename","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"middlename","state":errors.length > 3 ? false : null,"name":"middlename","placeholder":""},model:{value:(_vm.form.middlename),callback:function ($$v) {_vm.$set(_vm.form, "middlename", $$v)},expression:"form.middlename"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Выберите область'),"label-for":"region_id"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Выберите область'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"region_id","state":errors.length > 0 ? false : null,"name":"name","value-field":"id","text-field":"name_uz","placeholder":_vm.$t('Выберите область'),"options":_vm.regions.data},model:{value:(_vm.form.region_id),callback:function ($$v) {_vm.$set(_vm.form, "region_id", $$v)},expression:"form.region_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Выберите район'),"label-for":"city_id"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Выберите район'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"city_id","state":errors.length > 0 ? false : null,"name":"name","value-field":"id","text-field":"name_uz","placeholder":_vm.$t('Выберите район'),"options":_vm.cities.data},model:{value:(_vm.form.city_id),callback:function ($$v) {_vm.$set(_vm.form, "city_id", $$v)},expression:"form.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Адрес'),"label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 3 ? false : null,"name":"name","placeholder":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Ulushi(%)'),"label-for":"profit_percent"}},[_c('validation-provider',{attrs:{"name":"profit_percent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"id":"profit_percent","type":"number","state":errors.length > 3 ? false : null,"name":"profit_percent","placeholder":"40%"},model:{value:(_vm.form.profit_percent),callback:function ($$v) {_vm.$set(_vm.form, "profit_percent", $$v)},expression:"form.profit_percent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }