<template>
  <b-modal
    v-model="visible"
    size="lg"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form class="auth-login-form mt-2">
        <b-row>
          <b-col cols="4">
            <b-form-group
              :label="$t('Введите паспорт')"
              label-for="v-passport"
            >
              <validation-provider
                #default="{ errors }"
                name="v-passport"
                rules="required"
              >
                <b-form-input
                  id="v-passport"
                  v-model="form.passport"
                  v-mask="'AA#######'"
                  :state="errors.length > 3 ? false : null"
                  placeholder="AA0000000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Введите ПИНФЛ')"
              label-for="v-pin"
            >
              <validation-provider
                #default="{ errors }"
                name="v-pin"
                rules="required"
              >
                <b-form-input
                  id="v-pin"
                  v-model="form.pin"
                  v-mask="'##############'"
                  :state="errors.length > 3 ? false : null"
                  placeholder="00000000000000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Введите телефон')"
              label-for="v-phone"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Введите телефон')"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    +998
                  </b-input-group-prepend>

                  <b-form-input
                    id="v-phone"
                    v-model="form.phone"
                    v-mask="'#########'"
                    name="v-phone"
                    :state="errors.length > 3 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Фамилия')"
              label-for="lastname"
            >
              <validation-provider
                #default="{ errors }"
                name="lastname"
                rules="required"
              >
                <b-form-input
                  id="lastname"
                  v-model="form.lastname"
                  :state="errors.length > 3 ? false : null"
                  name="lastname"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Имя')"
              label-for="firstname"
            >
              <validation-provider
                #default="{ errors }"
                name="firstname"
                rules="required"
              >
                <b-form-input
                  id="firstname"
                  v-model="form.firstname"
                  :state="errors.length > 3 ? false : null"
                  name="firstname"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Отчества')"
              label-for="middlename"
            >
              <validation-provider
                #default="{ errors }"
                name="middlename"
                rules="required"
              >
                <b-form-input
                  id="middlename"
                  v-model="form.middlename"
                  :state="errors.length > 3 ? false : null"
                  name="middlename"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('Выберите область')"
              label-for="region_id"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Выберите область')"
                rules="required"
              >
                <b-form-select
                  id="region_id"
                  v-model="form.region_id"
                  :state="errors.length > 0 ? false : null"
                  name="name"
                  value-field="id"
                  text-field="name_uz"
                  :placeholder="$t('Выберите область')"
                  :options="regions.data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('Выберите район')"
              label-for="city_id"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Выберите район')"
                rules="required"
              >
                <b-form-select
                  id="city_id"
                  v-model="form.city_id"
                  :state="errors.length > 0 ? false : null"
                  name="name"
                  value-field="id"
                  text-field="name_uz"
                  :placeholder="$t('Выберите район')"
                  :options="cities.data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="9">
            <b-form-group
              :label="$t('Адрес')"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-input
                  id="address"
                  v-model="form.address"
                  :state="errors.length > 3 ? false : null"
                  name="name"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              :label="$t('Ulushi(%)')"
              label-for="profit_percent"
            >
              <validation-provider
                #default="{ errors }"
                name="profit_percent"
                rules="required"
              >
                <b-form-input
                  id="profit_percent"
                  v-model="form.profit_percent"
                  v-mask="'##'"
                  type="number"
                  :state="errors.length > 3 ? false : null"
                  name="profit_percent"
                  placeholder="40%"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {
        id: null,
        pin: null,
        passport: null,
        lastname: '',
        firstname: '',
        middlename: '',
        region_id: null,
        profit_percent: null,
        city_id: null,
        phone: null,
        address: null,
      },
      roles: [],

      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      cities: 'city/GET_ITEMS',
      regions: 'region/GET_ITEMS',
    }),
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
        }, 200)
      }
    },
    'form.region_id': function (newVal) {
      if (newVal) {
        this.getCities(newVal)
      }
    },
  },
  mounted() {
    this.title = this.$t('Добавить инвестор')
    this.$root.$on('add', data => {
      this.visible = true
    })
    this.getRegionsAction({ per_page: 999 })
  },
  methods: {
    getCities(cityId) {
      this.fetchCities({ region_id: cityId })
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.$emit('onSuccess')
            this.visible = false
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.getItem({
        id: item.id,
      }).then(res => {
        this.form.id = res.data.id
        this.form.pin = res.data.pin
        this.form.passport = res.data.passport
        this.form.lastname = res.data.lastname
        this.form.firstname = res.data.firstname
        this.form.middlename = res.data.middlename
        this.form.region_id = res.data.region_id
        this.form.profit_percent = res.data.profit_percent
        this.form.city_id = res.data.city_id
        this.form.phone = res.data.phone
        this.form.address = res.data.address

        this.visible = true
      })
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      getRegionsAction: 'region/index',
      fetchCities: 'city/index',
      storeItem: 'investment/store',
      getItem: 'investment/show',
      updateItem: 'investment/update',
    }),
  },
}
</script>

<style scoped></style>
